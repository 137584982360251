
.input-as-status {
    .disabledValueContainer {
        @include status-style(2px 15px, 100px, 20px);
        @include status-color(transparent, transparent, transparent);
    }

    &.application-status_ACCOUNT_VERIFICATION {
        .disabledValueContainer {
            @include status-color(var(--verification-border-color), var(--verification-background), var(--verification-color));
        }
    }

    &.application-status_ON_KYC {
        .disabledValueContainer {
            @include status-color(var(--kyc-border-color), var(--kyc-background), var(--kyc-color));
        }
    }
    
    &.application-status_VERIFICATION {
        .disabledValueContainer {
            @include status-color(var(--verification-border-color), var(--verification-background), var(--verification-color));
        }
    }

    &.application-status_UPDATE_REQUESTED {
        .disabledValueContainer {
            @include status-color(var(--update-requested-border-color), var(--update-requested-background), var(--update-requested-color));
        }
    }

    &.application-status_UNDERWRITING {
        .disabledValueContainer {
            @include status-color(var(--underwriting-border-color), var(--underwriting-background), var(--underwriting-color));
        }
    }

    &.application-status_DECLINED {
        .disabledValueContainer {
            @include status-color(var(--declined-border-color), var(--declined-background), var(--declined-color));
        }
    }

    &.application-status_CANCELED {
        .disabledValueContainer {
            @include status-color(var(--canceled-border-color), var(--canceled-background), var(--canceled-color));
        }
    }

    &.application-status_APPROVED {
        .disabledValueContainer {
            @include status-color(var(--approved-border-color), var(--approved-background), var(--approved-color));
        }
    }

    &.application-status_DISBURSED {
        .disabledValueContainer {
            @include status-color(var(--disbursed-border-color), var(--disbursed-background), var(--disbursed-color));
        }
    }

    &.application-status_ERROR {
        .disabledValueContainer {
            @include status-color(var(--canceled-border-color), var(--canceled-border-color), var(--canceled-color));
        }
    }

    &.loan-status_PENDING {
        .disabledValueContainer {
            @include status-color(var(--pending-border-color), var(--pending-background), var(--pending-color));
        }
    }

    &.loan-status_ACTIVE {
        .disabledValueContainer {
            @include status-color(var(--active-border-color), var(--active-background), var(--active-color));
        }
    }

    &.loan-status_ERROR {
        .disabledValueContainer {
            @include status-color(var(--error-border-color), var(--error-background), var(--error-color));
        }
    }

    &.loan-status_OVERDUE {
        .disabledValueContainer {
            @include status-color(var(--overdue-border-color), var(--overdue-background), var(--overdue-color));
        }
    }

    &.loan-status_WRITTEN_OFF {
        .disabledValueContainer {
            @include status-color(var(--writtenOff-border-color), var(--writtenOff-background), var(--writtenOff-color));
        }
    }

    &.loan-status_WRITTEN_OFF {
        .disabledValueContainer {
            @include status-color(var(--processing-border-color), var(--processing-background), var(--processing-color));
        }
    }

    &.loan-status_REPAID {
        .disabledValueContainer {
            @include status-color(var(--repaid-border-color), var(--repaid-background), var(--repaid-color));
        }
    }

    &.transaction-status_REJECT {
        .disabledValueContainer {
            @include status-color(var(--canceled-border-color), var(--canceled-background), var(--canceled-color));
        }
    }

    &.transaction-status_PENDING {
        .disabledValueContainer {
            @include status-color(var(--pending-border-color), var(--pending-background), var(--pending-color));
        }
    }

    &.transaction-status_IN_PROGRESS {
        .disabledValueContainer {
            @include status-color(var(--approved-border-color), var(--approved-background), var(--approved-color));
        }

    }

    &.transaction-status_FAILED {
        .disabledValueContainer {
            @include status-color(var(--canceled-border-color), var(--canceled-background), var(--canceled-color));
        }
    }

    &.transaction-status_CANCELED {
        .disabledValueContainer {
            @include status-color(var(--canceled-border-color), var(--canceled-background), var(--canceled-color));
        }
    }

    &.transaction-status_ACTIVE {
        .disabledValueContainer {
            @include status-color(var(--active-border-color), var(--active-background), var(--active-color));
        }

    }

    &.transaction-status_SUCCESSFUL {
        .disabledValueContainer {
            @include status-color(var(--active-border-color), var(--active-background), var(--active-color));
        }
    }

    &.transaction-status_REJECTED {
        .disabledValueContainer {
            @include status-color(var(--canceled-border-color), var(--canceled-background), var(--canceled-color));
        }
    }

    &.transaction-status_COMPLETED {
        .disabledValueContainer {
            @include status-color(var(--active-border-color), var(--active-background), var(--active-color));
        }
    }

    &.transaction-status_ERROR {
        .disabledValueContainer {
            @include status-color(var(--error-border-color), var(--error-background), var(--error-color));
        }
    }
}